import { useFetch } from '#app'
// import { useAuthcStore } from '~/stores'
import type { ResponseType } from '~/types/index'

export async function useRequest<T = any>(
  request: Ref<string> | string | ComputedRef<string>,
  method: 'get' | 'post' | 'put' | 'delete' = 'get',
  options: Parameters<typeof useFetch<ResponseType<T>>>[1] = {},
) {
  const user = useUserStore()
  // if (import.meta.client)
  //   await authcStore.done

  const { data, error, pending, refresh, execute } = await useFetch<ResponseType<T>>(request, {
    method,
    headers: user?.token
      ? {
          Authorization: user?.token,
        }
      : {},
    ...options,
    onRequest({ request, options }) {
      // Set the request headers
      options.headers = options.headers || {}

      options.headers.Authorization = `${user.token}`
    },

    async onResponse(res) {
      if (res.response.status !== 200)
        return

      // if (res.response._data.status !== 0) {
      //   // MessagePlugin('error', res.response._data?.message)
      //   const Error = unref(options?.onResponseError)
      //   console.log("Error", unref(options?.onResponse));

      //   return new Error(res.response._data?.status)
      // }

      const Response = unref(options?.onResponse)
      return Response?.(res)
    },
    async onResponseError(err) {
      if (err.response.status === 401) {
        user.logout()
        useRouter().push('/app/login')
      }

      // const Error = unref(options?.onResponseError)
      return unref(options?.onResponseError)?.(err)
    },
  })

  return {
    data,
    pending,
    error,
    refresh,
    execute,
  }
}
